<template>
    <div class='base-page'>
       <div class='base-header'>
            <div class="header-img" ></div>
       </div>
       <div class='base-main'>
            <div class='main-t'>
                <van-field
                    v-model="liveCity"
                    is-link
                    readonly
                    name="area"
                    label="现居城市"
                    placeholder="请选择居住城市"
                    @click="showArea = true"
                    />
                <van-popup v-model:show="showArea" position="bottom">
                    <van-area
                        :area-list="areaList"
                        :columns-num="3"
                        @confirm="onConfirm"
                        @cancel="showArea = false"
                    />
                </van-popup>
            </div>
            <div class='main-line'>
                <span class='main-line-name'>详细地址</span>
                    <!-- :value='userInfo.nickName'value="赵达" -->
                <input class='main-input' placeholder="请输入现居住地址" :value="cityAddress" @change='cityChange($event)'/>
            </div>
            <div class='main-t'>
                <van-field
                    v-model="infoValue"
                    is-link
                    readonly
                    name="picker"
                    label="学历"
                    placeholder="请选择学历"
                    @click="showPicker = true"
                    />
                    <van-popup v-model:show="showPicker" position="bottom">
                    <van-picker
                        value-key='value'
                        :columns="eduPickerData"
                        @confirm="modalCheck"
                        @cancel="showPicker = false"
                    />
                    </van-popup>
            </div>
            <div class='main-t'>
                <van-field
                    v-model="MonthlyValue"
                    is-link
                    readonly
                    name="picker"
                    label="个人月收入"
                    placeholder="请选择"
                    @click="showMonthly = true"
                    />
                    <van-popup v-model:show="showMonthly" position="bottom">
                    <van-picker
                        value-key='value'
                        :columns="columnsList"
                        @confirm="modalColumns"
                        @cancel="showMonthly = false"
                    />
                    </van-popup>
            </div>
            <div class='main-t'>
                <van-field
                    v-model="marriageValue"
                    is-link
                    readonly
                    name="picker"
                    label="婚姻状况"
                    placeholder="请选择婚姻状况"
                    @click="showMarriage = true"
                    />
                    <van-popup v-model:show="showMarriage" position="bottom">
                    <van-picker
                        value-key='value'
                        :columns="marriageList"
                        @confirm="modalMarriage"
                        @cancel="showMarriage = false"
                    />
                    </van-popup>
            </div>
            <div v-if='MarriageStatu==true'>
                 <div class='main-line'>
                    <span class='main-line-name'>配偶姓名</span>
                        <!-- :value='userInfo.nickName'value="赵达" -->
                    <input class='main-input' placeholder="请输入配偶姓名" :value="spouseName" @change='spouseNameChange($event)'/>
                </div>  
                 <div class='main-line'>
                    <span class='main-line-name'>配偶身份证号</span>
                        <!-- :value='userInfo.nickName'value="赵达" -->
                    <input class='main-input' placeholder="请输入配偶身份证号" :value="spouseId" @change='spouseIdChange($event)'/>
                </div>
                <div class='main-line'>
                    <span class='main-line-name'>配偶工作地址</span>
                        <!-- :value='userInfo.nickName'value="赵达" -->
                    <input class='main-input' placeholder="请输入配偶工作地址" :value="spouseAddress" @change='spouseAddressChange($event)'/>
                </div>
                <div class='main-line'>
                    <span class='main-line-name'>配偶工作单位</span>
                        <!-- :value='userInfo.nickName'value="赵达" -->
                    <input class='main-input' placeholder="请输入配偶工作单位" :value="spouseWorkName" @change='spouseWorkChange($event)'/>
                </div>
                <div class='main-line'>
                    <span class='main-line-name'>配偶手机号码</span>
                        <!-- :value='userInfo.nickName'value="赵达" -->
                    <input class='main-input' placeholder="请输入配偶手机号码" type="tel" maxlength="11" :value="spouseMobile" @change='spouseMobileChange($event)'/>
                </div>
            </div>
            <div class=' main-t city-work'>
                <van-field
                    v-model="cityVal"
                    is-link
                    readonly
                    name="area"
                    label="工作城市"
                    placeholder="请选择工作城市"
                    @click="showAreacity = true"
                    />
                <van-popup v-model:show="showAreacity" position="bottom">
                    <van-area
                        :area-list="areaList"
                        @confirm="onCity"
                        @cancel="showAreacity = false"
                    />
                </van-popup>
            </div>
            <div class='main-line main-y'>
                <span class='main-line-name'>公司全称</span>
                <input class='main-input' placeholder="请输入公司全称" :value="companyVal" @change='companyChange($event)'/>
            </div>
            <div class='main-line'>
                <span class='main-line-name'>工作地址</span>
                <input class='main-input' placeholder="请输入详细工作地址" :value="workAddress" @change='workChange($event)'/>
            </div>
            <div class="infrom-main" @click='contactF'>
                <div class='time-r'>常用联系人1</div>
                <div class='time-l'>
                    <input class='loan-input'  readonly='readonly' v-model="contactValue"  />
                    <span class='infrom-icon'></span>
                </div>
            </div>
            <div class='main-t'>
                <van-field
                    v-model="relationVal"
                    is-link
                    readonly
                    name="picker"
                    label="与您的关系"
                    placeholder="请选择与您的关系"
                    @click="showRelation = true"
                    />
                    <van-popup v-model:show="showRelation" position="bottom">
                    <van-picker
                         value-key='value'
                        :columns="relationList"
                        @confirm="modalRelation"
                        @cancel="showRelation = false"
                    />
                    </van-popup>
            </div>
            <div class="infrom-main" @click='contactT'>
                <div class='time-r'>常用联系人2</div>
                <div class='time-l'>
                    <input class='loan-input'  readonly='readonly' v-model="contactTValue"  />
                    <span class='infrom-icon'></span>
                </div>
            </div>
            <div class='main-t'>
                <van-field
                    v-model="relationValT"
                    is-link
                    readonly
                    name="picker"
                    label="与您的关系"
                    placeholder="请选择与您的关系"
                    @click="showRelationT = true"
                    />
                    <van-popup v-model:show="showRelationT" position="bottom">
                    <van-picker
                        value-key='value'
                        :columns="relationList"
                        @confirm="modalRelationT"
                        @cancel="showRelationT = false"
                    />
                    </van-popup>
            </div>
       </div>
       <div class="base-btn" @click="subitm">保存</div>
       <div class='base-footer-text'>本平台承诺保护您的信息安全</div>
    </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'
import { areaList } from '@vant/area-data';
import Modal from '@/components/Modal/Modal.vue'
import { Toast } from "vant";

export default ({
    components: {
        Modal
    },
    setup() {
        const state=reactive({
            liveCity:'',
            liveCode:'',//现居住code码
            cityVal:'',
            workCode:'',
            showArea:false,
            cityAddress:'',
            showback: false,
            infoValue:'',
            infoVal:'',
            MonthlyVal:'',
            marriageVal:'',
            eduPickerData:[],
            showPicker:false,
            MonthlyValue:'',//收入
            showMonthly:false,
            showMarriage:false,
            marriageValue:'',//婚姻
            columnsList:[],
            marriageList: [],
            cityVal:'',
            showAreacity:false,
            relationVal:'',
            relationList:[],
            showRelation:false,
            relationValT:'',
            showRelationT:false,
            companyVal:'',//公司名称
            workAddress:'',
            contactValue:'',
            contactTValue:'',
            relationV:'',
            relationVT:'',
            mobileList:[],//联系人数组
            MarriageStatu:false,//已婚弹框
            spouseName:'',
            spouseId:'',
            spouseAddress:'',
            spouseWorkName:'',
            spouseMobile:'',
            linkerName:'',//联系人1
            linkerMobile:'',//联系人1手机号
            linkerNames:'',//联系人2
            linkerMobiles:'',//联系人2手机号


        })
       const baseInfo=async()=>{
           const res=await API.baseInit({})
           state.eduPickerData=res.educationList
           state.columnsList=res.incomeStatusList
           state.marriageList=res.maritalStatusList
           state.relationList=res.linkerRelateTypeList
       }
       //身份证输入校验
       const checkIDCard=(idcode)=>{
        
                // 加权因子
                var weight_factor = [7,9,10,5,8,4,2,1,6,3,7,9,10,5,8,4,2];
                // 校验码
                var check_code = ['1', '0', 'X' , '9', '8', '7', '6', '5', '4', '3', '2'];
            
                var code = idcode + "";
                var last = idcode[17];//最后一位
            
                var seventeen = code.substring(0,17);
            
                // ISO 7064:1983.MOD 11-2
                // 判断最后一位校验码是否正确
                var arr = seventeen.split("");
                var len = arr.length;
                var num = 0;
                for(var i = 0; i < len; i++){
                    num = num + arr[i] * weight_factor[i];
                }
                
                // 获取余数
                var resisue = num%11;
                var last_no = check_code[resisue];
            
                var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X]|[x])$/;
            
                // 判断格式是否正确
                var format = idcard_patter.test(idcode);

                // console.log(format,last.toUpperCase(),last_no,88888888)

                let lastUpp = last.toUpperCase() ; 
            
                // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
                return lastUpp === last_no && format ? true : false;
       }
       const onConfirm = (areaValues) => {
           
            state.showArea = false;
            state.liveCity = areaValues
            .filter((item) => !!item)
            .map((item) => item.name)
            .join('');
            state.liveCode=areaValues
            .filter((item) => !!item)
            .map((item) => item.code)
            .join(',');
           console.log(state.liveCode);
        }
        const onCity = (areaValues) => {
            state.showAreacity = false;
            state.cityVal = areaValues
            .filter((item) => !!item)
            .map((item) => item.name)
            .join('');
            state.workCode=areaValues
            .filter((item) => !!item)
            .map((item) => item.code)
            .join(',');
        }
        const modalCheck = (e) => {
        //    console.log(e.key,997);
            state.infoValue = e.value;
            state.infoVal=e.key;
            state.showPicker = false;
        };
        const modalColumns=(e)=>{
      
            state.MonthlyValue=e.value
            state.MonthlyVal=e.key
            state.showMonthly=false
        }
        const modalMarriage=(e)=>{
            if(e.key=='MARRIED'){
                //弹框
                state.MarriageStatu=true
            }else{
                state.MarriageStatu=false
            }
            state.marriageValue=e.value
            state.marriageVal=e.key
            state.showMarriage=false
        }
        const spouseNameChange=(e)=>{
             state.spouseName=e.target.value
        }
        const spouseIdChange=(e)=>{
            if(!checkIDCard(e.target.value) && e.target.value.length>=15 && e.target.value.length>18){
                Toast('请输入正确的身份证号');
                return false;
                
            }
           state.spouseId=e.target.value
           
           
        }
        const spouseAddressChange=(e)=>{
           state.spouseAddress=e.target.value
        }
        const spouseWorkChange=(e)=>{
             state.spouseWorkName=e.target.value
          
        }
        const spouseMobileChange=(e)=>{
            // console.log(e.target.value.length,9993)
            state.spouseMobile=e.target.value
            
        }
        const modalRelation=(e)=>{
            // console.log(e,999);
            state.relationVal=e.value
            state.relationV=e.key
            state.showRelation=false
        }
        const modalRelationT=(e)=>{
            state.relationValT=e.value
            state.relationVT=e.key
            state.showRelationT=false
        }
        const cityChange=(e)=>{
            state.cityAddress=e.target.value
        }
        const companyChange=(e)=>{
            
            state.companyVal=e.target.value
        }
        const workChange=(e)=>{
           
            state.workAddress=e.target.value,22
        }
        //联系人
        const contactF=()=>{
            //调用app方法
            APP.GET_CONTACT(function(res){
                state.contactValue=res.name + res.mobileNo
                state.linkerName=res.name//联系人1
                state.linkerMobile=res.mobileNo//联系人1手机号
                //上传整个通讯录
                addressBooksub()
            })
            
        }
        const contactT=()=>{
             APP.GET_CONTACT(function(res){
                state.contactTValue=res.name + res.mobileNo
                state.linkerNames=res.name//联系人2
                state.linkerMobiles=res.mobileNo//联系人2手机号
                // APP.Alert(res.name)
                // "name" : "HaroAnna",
                // "mobileNo" : "5555228243"
                addressBooksub()
            })
        }
        const subitm=async()=>{
            window.TDAPP.onEvent("基础信息提交-进入");
            if(state.liveCode==''){
                Toast('请选择现居住城市')
                return false;
            }
            if(state.cityAddress==''){
                Toast('请填写现居住地址')
                return false;
            }
            if(state.infoVal==''){
                Toast('请选择学历')
                return false;
            }
            if(state.MonthlyVal==''){
                 Toast('请选择月收入')
                return false;
            }
            if(state.marriageVal==''){
                Toast('请选择婚姻状况')
                return false;
            }
            if(state.MarriageStatu==true){
                if(state.spouseName==''){
                    Toast('请填写配偶姓名')
                    return false;
                }
                if(state.spouseId==''){
                    Toast('请填写配偶身份证')
                    return false;
                }
                if(state.spouseAddress==''){
                    Toast('请填写配偶工作地址')
                    return false;
                }
                if(state.spouseWorkName==''){
                    Toast('请填写配偶工作单位')
                    return false;
                }
                if(state.spouseMobile==''){
                    Toast('请填写配偶手机号')
                    return false;
                }
            }else{
                state.spouseName=''
                state.spouseId=''
                state.spouseAddress=''
                state.spouseWorkName=''
                state.spouseMobile=''
            }
            if(state.workCode==''){
                Toast('请选择工作城市')
                return false;
            }
            if(state.companyVal==''){
                Toast('请填写公司名称')
                return false;
            }
            if(state.workAddress==''){
                Toast('请填写工作地址')
                return false;
            }
            if(state.contactValue==''){
                Toast('请选择联系人1')
                return false;
            }
            if(state.relationVal==''){
                Toast('请选择与联系人1的关系')
                return false;
            }
            if(state.contactTValue==''){
                Toast('请选择联系人2')
                return false;
            }
            if(state.relationValT==''){
                Toast('请选择与联系人2的关系')
                return false;
            }
            if(state.contactValue==state.contactTValue){
                Toast('联系人1与联系人2不能相同')
                return false;
            }
        
                
            const res=await API.baseSubmit({
                liveCity: state.liveCode,
                homeAddr: state.cityAddress,
                degree: state.infoVal,//教育
                marital: state.marriageVal,//婚姻
                workCity: state.workCode,//工作城市
                companyName:state.companyVal, //公司名称
                workAddress: state.workAddress,//工作地址
                salaryRange: state.MonthlyVal,
                coupeName: state.spouseName,//配偶姓名
                coupeCertNo: state.spouseId,//配偶身份证
                coupeCompany: state.spouseWorkName,//配偶公司名
                coupeMobile: state.spouseMobile,//配偶手机号
                contactsList: [
                    {
                        linkerName:state.linkerName,
                        linkerMobile: state.linkerMobile,
                        relation: state.relationV
                    },
                    {
                        linkerName: state.linkerNames,
                        linkerMobile: state.linkerMobiles,
                        relation:state.relationVT
                    }
                ],
            })
            console.log(res,3422)
            //成功跳转到信息提交页
            if(res){
                
                console.log(res.authFinishUrl,8654)
                APP.JUMP_H5(res.authFinishUrl)
            }

        }
        // const goback=()=>{
        //     APP.GO_BACK(2)
        // }
        //上传通讯录
        const addressBooksub=()=>{
            //获取通讯录数据
            APP.UPLOAD_DATA(function(res){
                    console.log(res,999)
                    if(res){
                        submobile(res)
                    }
            })
            
        }
        const submobile=async(res)=>{
            await API.addressBook({
                collectType:"1",
                collectJsonData:JSON.stringify(res)//整个通讯录
            })
        }
        onMounted(()=>{
            APP.SET_TITLE('基本信息')
            //隐藏返回按钮
            //   APP.IS_SHOW_BACK(false)
            baseInfo()
            
        })
        return{
            ...toRefs(state),
            areaList,
            onConfirm,
            cityChange,
            modalCheck,
            modalColumns,
            modalMarriage,
            onCity,
            modalRelation,
            modalRelationT,
            companyChange,
            workChange,
            contactF,
            contactT,
            subitm,
            spouseNameChange,
            spouseIdChange,
            spouseAddressChange,
            spouseWorkChange,
            spouseMobileChange
        }
    },
})
</script>